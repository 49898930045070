import Lottie from "lottie-react";
import { uploadResume } from "../api/resBuilder/resumeBuilder";

const base_imgs_url = import.meta.env.VITE_CLOUD_FRONT;

export const icons = {
  CommonHeader: {
    avuaLogo: `${base_imgs_url}/Assets/icons/avua_logo_tab.svg`,
    header_logo: `${base_imgs_url}/Assets/icons/header_logo.svg`,
    avuaRecommendedLogo: `${base_imgs_url}/Assets/icons/avua-recommended-logo.svg`,
    avua_header_logo: `${base_imgs_url}/Assets/icons/avua_logo.svg`,
  },

  landingHeader: {
    logo: `${base_imgs_url}/Assets/icons/LandingHeaderLogo.svg`,
    testimonial: `${base_imgs_url}/Assets/icons/testimonial_icon.svg`,
    testimonial_stars: `${base_imgs_url}/Assets/icons/testimonal_stars.svg`,
  },
  careerGrowth: {
    greenTickCircle: `${base_imgs_url}/Assets/icons/green_tick_circle.svg`,
    coverLatterIocn: `${base_imgs_url}/Assets/icons/coverLatterIocn.svg`,
    careerTraJectoryIcon: `${base_imgs_url}/Assets/icons/careerTraJectoryIcon.svg`,
    aiInterveiewsIcon: `${base_imgs_url}/Assets/icons/aiInterveiewsIcon.svg`,
    atsFriendlyResumeIcon: `${base_imgs_url}/Assets/icons/atsFriendlyResumeIcon.svg`,
    salaryInsightsIcon: `${base_imgs_url}/Assets/icons/salaryInsightsIcon.svg`,
    unlimitedCvAnalysisIcon: `${base_imgs_url}/Assets/icons/unlimitedCvAnalysisIcon.svg`,
    allInOneIcon: `${base_imgs_url}/Assets/icons/allInOneIcon.svg`,
    aiPoweredIcon: `${base_imgs_url}/Assets/icons/aiPoweredIcon.svg`,
    atsOptimizedIcon: `${base_imgs_url}/Assets/icons/atsOptimizedIcon.svg`,
    affordableIcon: `${base_imgs_url}/Assets/icons/affordableIcon.svg`,
    aiIconWhite: `${base_imgs_url}/Assets/icons/aiIconWhite.svg`,
  },
  aboutUs: {
    bulb_icon: `${base_imgs_url}/Assets/icons/bulb_icon.svg`,
    check_icon: `${base_imgs_url}/Assets/icons/check_icon.svg`,
    checkRound_icon: `${base_imgs_url}/Assets/icons/checkRound_icon.svg`,
    magGlass_icon: `${base_imgs_url}/Assets/icons/magGlass_icon.svg`,
    time_icon: `${base_imgs_url}/Assets/icons/time_icon.svg`,
    linkedin: `${base_imgs_url}/Assets/icons/linkedin_icon.svg`,
  },

  applicant: {
    timeValue: `${base_imgs_url}/Assets/icons/timeValue.svg`,
    resumes: `${base_imgs_url}/Assets/icons/resumes.svg`,
    jobMarket: `${base_imgs_url}/Assets/icons/jobMarket.svg`,
    valueableTime: `${base_imgs_url}/Assets/icons/valueableTime.svg`,
    search_icon: `${base_imgs_url}/Assets/icons/search_icon.svg`,
    error_triangle: `${base_imgs_url}/Assets/icons/alert-triangle.svg`,
    Cv_analysis: `${base_imgs_url}/Assets/icons/Cv_analysis.svg`,
  },

  Employer: {
    limitedReach: `${base_imgs_url}/Assets/icons/limitedReach.svg`,
    timeConsuming_icon: `${base_imgs_url}/Assets/icons/timeConsuming_icon.svg`,
    candExp_icon: `${base_imgs_url}/Assets/icons/candExp_icon.svg`,
    comDelay_icon: `${base_imgs_url}/Assets/icons/comDelay_icon.svg`,
    highCosts_icon: `${base_imgs_url}/Assets/icons/highCosts_icon.svg`,
    lackAnalytics_icon: `${base_imgs_url}/Assets/icons/humanAnalytics_icon.svg`,
    humanBias_icon: `${base_imgs_url}/Assets/icons/humanBias_icon.svg`,
    inEval_icon: `${base_imgs_url}/Assets/icons/inEval_icon.svg`,
    invoiceDownload: `${base_imgs_url}/Assets/icons/invoiceDownload.svg`,
    applicantsSearch: `${base_imgs_url}/Assets/icons/applicantsSearch.svg`,
    mailIcon: `${base_imgs_url}/Assets/icons/mailIcon.svg`,
    dashboardCv: `${base_imgs_url}/Assets/icons/dashboardCv.svg`,
    nextIcon: `${base_imgs_url}/Assets/icons/nextArrowIcon.svg`,
    postedJobs: `${base_imgs_url}/Assets/icons/postedJobs.svg`,
    noDraftIcon: `${base_imgs_url}/Assets/icons/noDraftIcon.svg`,
    deleteJob: `${base_imgs_url}/Assets/icons/deleteJob.svg`,
    newAvuaLogo: `${base_imgs_url}/Assets/icons/newAvuaLogo.svg`,
  },

  avuaPool: {
    limitedReach: `${base_imgs_url}/Assets/icons/limitedReach.svg`,
    Pool_search: `${base_imgs_url}/Assets/icons/Pool_search.svg`,
    Pool_industry: `${base_imgs_url}/Assets/icons/pool_industry.svg`,
    Pool_check: `${base_imgs_url}/Assets/icons/pool-check.svg`,
    pool_result: `${base_imgs_url}/Assets/icons/pool_result.svg`,
    check: `${base_imgs_url}/Assets/icons/check.svg`,
    defaultScreen: `${base_imgs_url}/Assets/icons/noViewedCandidate.svg`,
    poolSearchIcon: `${base_imgs_url}/Assets/icons/poolSearchIcon.svg`,
    noSearchHistory: `${base_imgs_url}/Assets/icons/noSearchHistory.svg`,
    locationAddField: `${base_imgs_url}/Assets/icons/locationAddField.svg`,
    clearAll: `${base_imgs_url}/Assets/icons/minus-circle.svg`,
    filtersIcon: `${base_imgs_url}/Assets/icons/filtersIcon.svg`,
    noticePrdIcon: `${base_imgs_url}/Assets/icons/noticePrdIcon.svg`,
    avuaDefaultImg: `${base_imgs_url}/Assets/icons/avuaDefaultImg.svg`,
    marker: `${base_imgs_url}/Assets/icons/marker.svg`,
    avuaPoolLogo: `${base_imgs_url}/Assets/icons/avuaPoolLogo.svg`,
    avuaPlus: `${base_imgs_url}/Assets/icons/avuaPlus.svg`,
    CustomLogo: `${base_imgs_url}/Assets/icons/CustomLogo.svg`,
    NewTagForUser: `${base_imgs_url}/Assets/icons/NewTagImg.svg`,
    bell_pool: `${base_imgs_url}/Assets/icons/bell_pool.svg`,
    setting_pool: `${base_imgs_url}/Assets/icons/settings_pool.svg`,
    dropdowm_pool: `${base_imgs_url}/Assets/icons/dropdown_pool.svg`,
    filter_search: `${base_imgs_url}/Assets/icons/filter_search.svg`,
    Ai_search: `${base_imgs_url}/Assets/icons/Ai_search_new.svg`,
    left_arrow_pool: `${base_imgs_url}/Assets/icons/left_arrow-pool.svg`,
    save_icon_pool: `${base_imgs_url}/Assets/icons/save_icon_pool.svg`,
    clear_all_pool: `${base_imgs_url}/Assets/icons/clear_all_pool.svg`,
    more_pool: `${base_imgs_url}/Assets/icons/more-pool.svg`,
    key_pool: `${base_imgs_url}/Assets/icons/key_pool.svg`,
    alert_triangle_pool: `${base_imgs_url}/Assets/icons/alert_triangle_pool.svg`,
    menu_pool: `${base_imgs_url}/Assets/icons/menu_pool.svg`,
    project_more: `${base_imgs_url}/Assets/icons/project_more.svg`,
    save_history: `${base_imgs_url}/Assets/icons/save_history_pool.svg`,
    file_text: `${base_imgs_url}/Assets/icons/file-text.svg`,
    no_notes_default: `${base_imgs_url}/Assets/icons/no_notes_default.svg`,
  },

  Recommended_Jobs: {
    calender: `${base_imgs_url}/Assets/icons/calender_job.svg`,
    Experience: `${base_imgs_url}/Assets/icons/Experience-jobs.svg`,
    Salary: `${base_imgs_url}/Assets/icons/Salary-jobs.svg`,
    Location: `${base_imgs_url}/Assets/icons/Location_job.svg`,
    Qualification: `${base_imgs_url}/Assets/icons/Qualification-jobs.svg`,
    industry: `${base_imgs_url}/Assets/icons/industry.svg`,
    company: `${base_imgs_url}/Assets/icons/company_size.svg`,
    phone: `${base_imgs_url}/Assets/icons/phone.svg`,
    mail: `${base_imgs_url}/Assets/icons/mail.svg`,
    website: `${base_imgs_url}/Assets/icons/website.svg`,
    Applied: `${base_imgs_url}/Assets/icons/Applied.svg`,
    incognito: `${base_imgs_url}/Assets/icons/incognito.svg`,
    default_office: `${base_imgs_url}/Assets/icons/default_office.svg`,
    message_icon: `${base_imgs_url}/Assets/icons/message_icon.svg`,
    ShortListed: `${base_imgs_url}/Assets/icons/ShortListed.svg`,
    defaultGenderNeutral: `${base_imgs_url}/Assets/icons/defaultGenderNeutral.svg`,
    noRecommendedCandidates: `${base_imgs_url}/Assets/icons/noRecommendedCandidates.svg`,
    avuaGuarantee: `${base_imgs_url}/Assets/icons/avuaGuarantee.svg`,
    unload_candidates: `${base_imgs_url}/Assets/icons/unload_candidates.svg`,
    noticePeriodIcon: `${base_imgs_url}/Assets/icons/noticePeriodIcon.svg`,
    jobBagIcon: `${base_imgs_url}/Assets/icons/jobBagIcon.svg`,
    skillsIcon: `${base_imgs_url}/Assets/icons/skillsIcon.svg`,
    salaryIcon: `${base_imgs_url}/Assets/icons/salaryIcon.svg`,
    no_recommended: `${base_imgs_url}/Assets/icons/no_recommended_job.svg`,
    ShortListed_job: `${base_imgs_url}/Assets/icons/shorlisted_candidate.svg`,
    default_company_logo: `${base_imgs_url}/Assets/icons/default_company_logo.svg`,
    user_icon: `${base_imgs_url}/Assets/icons/myJob_user.svg`,
    search_filter: `${base_imgs_url}/Assets/icons/search_filter.svg`,
    default_screen_jobs: `${base_imgs_url}/Assets/icons/default_screen_jobs.svg`,
    drop_down: `${base_imgs_url}/Assets/icons/chevron-down.svg`,
    drop_up: `${base_imgs_url}/Assets/icons/chevron-up.svg`,
    shorlist_mobile: `${base_imgs_url}/Assets/icons/shorlist_mobile.svg`,
    shorlist_details: `${base_imgs_url}/Assets/icons/shorlisted_details.svg`,
  },

  Profile: {
    plus_icon: `${base_imgs_url}/Assets/icons/plus_icon.svg`,
    IButton: `${base_imgs_url}/Assets/icons/IButton.svg`,
    cancel_icon: `${base_imgs_url}/Assets/icons/cancel_icon.svg`,
    trash: `${base_imgs_url}/Assets/icons/language_trash.svg`,
    Language_Plus_Icon: `${base_imgs_url}/Assets/icons/language_plusIcon.svg`,
    dateIcon: `${base_imgs_url}/Assets/icons/Pref_calendar.svg`,
    jobCard_icon: `${base_imgs_url}/Assets/icons/jobCard_icon.svg`,
  },

  Stepper: {
    Profile_icon: `${base_imgs_url}/Assets/icons/stepper_profile_icon.svg`,
    Contact_icon: `${base_imgs_url}/Assets/icons/stepper_contact_icon.svg`,
    Preferences_icon: `${base_imgs_url}/Assets/icons/stepper_pref_icon.svg`,
    Experience_icon: `${base_imgs_url}/Assets/icons/stepper_experience_icon.svg`,
    Education_icon: `${base_imgs_url}/Assets/icons/stepper_edu_icon.svg`,
    Additional_icon: `${base_imgs_url}/Assets/icons/stepper_additional_icon.svg`,
    Complete_icon: `${base_imgs_url}/Assets/icons/stepper_completed_icon.svg`,
    plus_icon: `${base_imgs_url}/Assets/icons/stepper_additional_icon.svg`,
  },

  Recruiter: {
    avua_Pool: `${base_imgs_url}/Assets/icons/recuiter_icon_1.svg`,
    Video_Resume: `${base_imgs_url}/Assets/icons/recuiter_icon_2.svg`,
    Incognito_Job: `${base_imgs_url}/Assets/icons/recuiter_icon_3.svg`,
    Verified_Talent: `${base_imgs_url}/Assets/icons/recuiter_icon_4.svg`,
    arrow: `${base_imgs_url}/Assets/icons/recruitPage_arrowCircled_icon.svg`,
  },

  UploadResume: {
    uploadingResume: `${base_imgs_url}/Assets/icons/uploadingResume_icon.svg`,
    buildProfileIcon: `${base_imgs_url}/Assets/icons/buildProfile_icon.svg`,
  },

  social: {
    facebook: `${base_imgs_url}/Assets/icons/facebook.svg`,
    twitter: `${base_imgs_url}/Assets/icons/twitter.svg`,
    instagram: `${base_imgs_url}/Assets/icons/instagram.svg`,
    linkedin: `${base_imgs_url}/Assets/icons/linkedin.svg`,
    google_img: `${base_imgs_url}/Assets/icons/google_img.svg`,
    playstore: `${base_imgs_url}/Assets/icons/playstore.svg`,
    applestore: `${base_imgs_url}/Assets/icons/applestore.svg`,
    authSocialLogin: `${base_imgs_url}/Assets/icons/authSocialLogin.svg`,
  },
  verify: {
    location: `${base_imgs_url}/Assets/icons/location.svg`,
    email: `${base_imgs_url}/Assets/icons/email.svg`,
    Verify_arrow: `${base_imgs_url}/Assets/icons/Verify_arrow.svg`,
    arrowStart: `${base_imgs_url}/Assets/icons/Verify_check.svg`,
    Verify_arrowStart: `${base_imgs_url}/Assets/icons/Verify_arrowStart.svg`,
    Timer: `${base_imgs_url}/Assets/icons/Verify_Timer.svg`,
    backButton: `${base_imgs_url}/Assets/icons/Verify_BackButton.svg`,
    Gradute: `${base_imgs_url}/Assets/icons/Verify_Gradute.svg`,
    office: `${base_imgs_url}/Assets/icons/Verify_office.svg`,
    arrowVector: `${base_imgs_url}/Assets/icons/Verify_arrowVector.svg`,
    user_plus: `${base_imgs_url}/Assets/icons/user-plus.svg`,
    Fade_user_plus: `${base_imgs_url}/Assets/icons/Fade_user_plus.svg`,
    Vetting_Work: `${base_imgs_url}/Assets/icons/Vetting_Work.svg`,
    Vetting_Student: `${base_imgs_url}/Assets/icons/Vetting_Student.svg`,
    Vetting_right: `${base_imgs_url}/Assets/icons/Vetting_right.svg`,
    info: `${base_imgs_url}/Assets/icons/info.svg`,
    x_circle: `${base_imgs_url}/Assets/icons/x-circle.svg`,
    alert_circle: `${base_imgs_url}/Assets/icons/alert-circle.svg`,
    Vetting_clock: `${base_imgs_url}/Assets/icons/Vetting_clock.svg`,
    Vetting_check: `${base_imgs_url}/Assets/icons/Vetting_check.svg`,
  },

  verifyExperienced: {
    arrowBack: `${base_imgs_url}/Assets/icons/Verify_arrowBack.svg`,
    arrowStart: `${base_imgs_url}/Assets/icons/Verify_arrowStart.svg`,
  },
  sigup: {
    Auth_error: `${base_imgs_url}/Assets/icons/Auth_error.svg`,
    // LoginInfoLogo:`${base_imgs_url}/Assets/icons/LoginInfoLogo.svg`,
    whatsNext: `${base_imgs_url}/Assets/icons/whatsNext.svg`,
    auth_emailVerify: `${base_imgs_url}/Assets/icons/auth_emailVerify.svg`,
    CompanyNameButton: `${base_imgs_url}/Assets/icons/CompanyNameButton.svg`,
  },
  Footer: {
    logo: `${base_imgs_url}/Assets/icons/LandingHeaderLogo.svg`,
    mapPin: `${base_imgs_url}/Assets/icons/map-pin.svg`,
    apple_icon: `${base_imgs_url}/Assets/icons/apple_new.svg`,
    playstore_icon: `${base_imgs_url}/Assets/icons/android_new.svg`,
    facebook: `${base_imgs_url}/Assets/icons/facebook_new_.svg`,
    instaIcon: `${base_imgs_url}/Assets/icons/insta_new.svg`,
    twitter: `${base_imgs_url}/Assets/icons/twitter_new.svg`,
    linkedin: `${base_imgs_url}/Assets/icons/linkedin_new.svg`,
    avuaFooter_logo: `${base_imgs_url}/Assets/icons/avua_footer_logo.svg`,
  },

  CustomPagination: {
    arrowUp: `${base_imgs_url}/Assets/icons/arrowUp.svg`,
    arrowDown: `${base_imgs_url}/Assets/icons/arrowDown.svg`,
    nextArrowIcon: `${base_imgs_url}/Assets/icons/next-page-icon.svg`,
    previousArrowIcon: `${base_imgs_url}/Assets/icons/previous-page-icon.svg`,
    nextIcon: `${base_imgs_url}/Assets/icons/next-arrow.svg`,
    prevIcon: `${base_imgs_url}/Assets/icons/previous-arrow.svg`,
  },
  social_footer: {
    apple: `${base_imgs_url}/Assets/icons/appleLogo.svg`,
    playstore: `${base_imgs_url}/Assets/icons/playstoreLogo.svg`,
  },
  social_auth: {
    Auth_facebook: `${base_imgs_url}/Assets/icons/Auth_facebook.svg`,
    Auth_linkedin: `${base_imgs_url}/Assets/icons/Auth_Linkedin.svg`,
    Auth_google: `${base_imgs_url}/Assets/icons/Auth_google.svg`,
  },

  Dashboard: {
    dashboard_icon: `${base_imgs_url}/Assets/icons/dashboard_icon.svg`,
    Profile_icon: `${base_imgs_url}/Assets/icons/profileicon.svg`,
    Login_icon: `${base_imgs_url}/Assets/icons/login_icon.svg`,
    ExcMark: `${base_imgs_url}/Assets/icons/excmark.svg`,
    camera_icon: `${base_imgs_url}/Assets/icons/cameraicon.svg`,
    Apply_job: `${base_imgs_url}/Assets/icons/Apply_job.svg`,
    Up_arrow: `${base_imgs_url}/Assets/icons/uparrow.svg`,
    Deactivate_Btn: `${base_imgs_url}/Assets/icons/btndeactivate.svg`,
    forward_arrow: `${base_imgs_url}/Assets/icons/forwardarrow.svg`,
    confirm_delete: `${base_imgs_url}/Assets/icons/confirmdelete.svg`,
    Reupload_icon: `${base_imgs_url}/Assets/icons/reuploadicon.svg`,
    Frame_1: `${base_imgs_url}/Assets/icons/frame-1.svg`,
    Frame_11: `${base_imgs_url}/Assets/icons/frame-11.svg`,
    Frame_12: `${base_imgs_url}/Assets/icons/frame-12.svg`,
    back_arrow: `${base_imgs_url}/Assets/icons/backarrow.svg`,
    clock_icon: `${base_imgs_url}/Assets/icons/clockicon.svg`,
    delete_video: `${base_imgs_url}/Assets/icons/group-2014.svg`,
    resume_Button: `${base_imgs_url}/Assets/icons/resumebtnline.svg`,
    resume_icon: `${base_imgs_url}/Assets/icons/resume_icon.svg`,
    Job_logo: `${base_imgs_url}/Assets/icons/Job_logo.svg`,
    VideoIcon: `${base_imgs_url}/Assets/icons/videoIcon.svg`,
    notification_icon: `${base_imgs_url}/Assets/icons/notification_icon.svg`,
    notificationLogo: `${base_imgs_url}/Assets/icons/notification-logo.svg`,
    uploadResumeIcon: `${base_imgs_url}/Assets/icons/uploadResume_icon.svg`,
    Left_icon: `${base_imgs_url}/Assets/icons/Left_icon.svg`,
    Right_icon: `${base_imgs_url}/Assets/icons/Right_icon.svg`,
    avua_udemy: `${base_imgs_url}/Assets/icons/avua_logo_udemy.svg`,
    Udemy_logo: `${base_imgs_url}/Assets/icons/Udemy_logo.svg`,
    header_plus: `${base_imgs_url}/Assets/icons/headerPlusIcon.svg`,
    header_notifications: `${base_imgs_url}/Assets/icons/headerNotificationIcon.svg`,
    header_arrow_down: `${base_imgs_url}/Assets/icons/headerChevronDownIcon.svg`,
    header_settings: `${base_imgs_url}/Assets/icons/headerSettingIcon.svg`,
    post_job_cover: `${base_imgs_url}/Assets/icons/dashboardPostJob.svg`,
    avua_pool_cover: `${base_imgs_url}/Assets/icons/dashboardAvuaPool.svg`,
    arrowRight: `${base_imgs_url}/Assets/icons/right_arrow.svg`,
    aiInterviewCard: `${base_imgs_url}/Assets/icons/aiInterviewCard.svg`,
    cvMaskingCard: `${base_imgs_url}/Assets/icons/cvMaskingCard.svg`,
    postJobCard: `${base_imgs_url}/Assets/icons/postJobCard.svg`,
    addCircle: `${base_imgs_url}/Assets/icons/add-circle.svg`,
    deleteCircle: `${base_imgs_url}/Assets/icons/delete-circle.svg`,
    moreActions: `${base_imgs_url}/Assets/icons/more-actions.svg`,
    Cv_analysis_dropdown: `${base_imgs_url}/Assets/icons/Cv_analysis_dropdown.svg`,
    Cv_analysis_upload: `${base_imgs_url}/Assets/icons/Cv_analysis_upload.svg`,
    Cv_trash: `${base_imgs_url}/Assets/icons/Cv_trash.svg`,
    pdf: `${base_imgs_url}/Assets/icons/pdf.svg`,
  },
  employerNav: {
    homeIcon: `${base_imgs_url}/Assets/icons/homeIcon.svg`,
    homeIconWhite: `${base_imgs_url}/Assets/icons/homeIconWhite.svg`,
    jobsspaceIcon: `${base_imgs_url}/Assets/icons/jobsspaceIcon.svg`,
    jobspaceIconWhite: `${base_imgs_url}/Assets/icons/jobspaceIconWhite.svg`,
    packageIcon: `${base_imgs_url}/Assets/icons/packageIcon.svg`,
    packageIconWhite: `${base_imgs_url}/Assets/icons/packageIconWhite.svg`,
    messageIcon: `${base_imgs_url}/Assets/icons/messageIcon.svg`,
    messageIconWhite: `${base_imgs_url}/Assets/icons/messageIconWhite.svg`,
    scheduleIcon: `${base_imgs_url}/Assets/icons/scheduleIcon.svg`,
    scheduleIconWhite: `${base_imgs_url}/Assets/icons/scheduleIconWhite.svg`,
    companyIcon: `${base_imgs_url}/Assets/icons/companyIcon.svg`,
    companyIconWhite: `${base_imgs_url}/Assets/icons/companyIconWhite.svg`,
    faqIcon: `${base_imgs_url}/Assets/icons/faqIcon.svg`,
    faqIconWhite: `${base_imgs_url}/Assets/icons/faqIconWhite.svg`,
    settingIcon: `${base_imgs_url}/Assets/icons/settingIcon.svg`,
    settingIconWhite: `${base_imgs_url}/Assets/icons/settingIconWhite.svg`,
    logoutIcon: `${base_imgs_url}/Assets/icons/logoutIcon.svg`,
    // new Design
    addPlanBtn: `${base_imgs_url}/Assets/icons/AddPlanBtn.svg`,
    navPostJobBold: `${base_imgs_url}/Assets/icons/navPostJobBold.svg`,
    navPostJob: `${base_imgs_url}/Assets/icons/navPostJob.svg`,
    navPostCv: `${base_imgs_url}/Assets/icons/navPostCv.svg`,
    navPostCvBold: `${base_imgs_url}/Assets/icons/navPostCvBold.svg`,
    navMessagesBold: `${base_imgs_url}/Assets/icons/navMessagesBold.svg`,
    navMessages: `${base_imgs_url}/Assets/icons/navMessages.svg`,
    navJobspaceBold: `${base_imgs_url}/Assets/icons/navJobspaceBold.svg`,
    navJobspace: `${base_imgs_url}/Assets/icons/navJobspace.svg`,
    navHomeBold: `${base_imgs_url}/Assets/icons/navHomeBold.svg`,
    navHome: `${base_imgs_url}/Assets/icons/navHome.svg`,
    navCompanyBold: `${base_imgs_url}/Assets/icons/navCompanyBold.svg`,
    navCompany: `${base_imgs_url}/Assets/icons/navCompany.svg`,
    navPackagesBold: `${base_imgs_url}/Assets/icons/navPackagesBold.svg`,
    navPackages: `${base_imgs_url}/Assets/icons/navPackages.svg`,
    navAvuaPoolBold: `${base_imgs_url}/Assets/icons/navAvuaPoolBold.svg`,
    navAvuaPool: `${base_imgs_url}/Assets/icons/navAvuaPool.svg`,
    navSmartHire: `${base_imgs_url}/Assets/icons/smart-hire.svg`,
    navSmartHireBold: `${base_imgs_url}/Assets/icons/smart-hire-bold.svg`,
    navCollapseIcon: `${base_imgs_url}/Assets/icons/navCollapseIcon.svg`,
    navExpandIcon: `${base_imgs_url}/Assets/icons/navExpandIcon.svg`,
  },
  avuaSmartHire: {
    barChart: `${base_imgs_url}/Assets/icons/bar-chart-icon.svg`,
    arrowDown: `${base_imgs_url}/Assets/icons/arrow-down.svg`,
    hideTranscript: `${base_imgs_url}/Assets/icons/hide-transcript-icon.svg`,
    showTranscript: `${base_imgs_url}/Assets/icons/show-transcript-icon.svg`,
    testInstructions: `${base_imgs_url}/Assets/imgs/testInstructions.svg`,
    permissionsImg1: `${base_imgs_url}/Assets/imgs/permissionsImg1.svg`,
    permissionsImg2: `${base_imgs_url}/Assets/imgs/permissionsImg2.svg`,
    permissionsImg3: `${base_imgs_url}/Assets/imgs/permissionsImg3.svg`,
    avuaAudioLogo: `${base_imgs_url}/Assets/icons/test-screen-logo.svg`,
    mic: `${base_imgs_url}/Assets/icons/mic_icon.svg`,
    mic_white: `${base_imgs_url}/Assets/icons/mic_white.svg`,
    whiteMic: `${base_imgs_url}/Assets/icons/whiteMic.svg`,
    recordIcon: `${base_imgs_url}/Assets/icons/recordIcon.svg`,
    endInterview: `${base_imgs_url}/Assets/imgs/joy.svg`,
    videoOff: `${base_imgs_url}/Assets/icons/video-off.svg`,
    permission_granted: `${base_imgs_url}/Assets/icons/permission-granted.svg`,
    instructions_webcam: `${base_imgs_url}/Assets/icons/instructions-webcam.svg`,
    instructions_switchTabs: `${base_imgs_url}/Assets/icons/instructions-switchTabs.svg`,
    instructions_environment: `${base_imgs_url}/Assets/icons/instructions-environment.svg`,
    instructions_dress: `${base_imgs_url}/Assets/icons/instructions-dress.svg`,
    instructions_confidence: `${base_imgs_url}/Assets/icons/instructions-confidence.svg`,
    instructions_calm: `${base_imgs_url}/Assets/icons/instructions-calm.svg`,
    doc_file: `${base_imgs_url}/Assets/icons/doc-file.svg`,
  },
  jobspace: {
    playIcon: `${base_imgs_url}/Assets/icons/playIcon.svg`,
    locationIcon: `${base_imgs_url}/Assets/icons/locationIcon.svg`,
    phoneIcon: `${base_imgs_url}/Assets/icons/phoneIcon.svg`,
    emailIcon: `${base_imgs_url}/Assets/icons/emailIcon.svg`,
    cashIcon: `${base_imgs_url}/Assets/icons/cashIcon.svg`,
    jobIcon: `${base_imgs_url}/Assets/icons/jobIcon.svg`,
    calendarIcon: `${base_imgs_url}/Assets/icons/calendarIcon.svg`,
    closeIcon: `${base_imgs_url}/Assets/icons/closeIcon.svg`,
    openToRelocate: `${base_imgs_url}/Assets/icons/open_to_relocate.svg`,
    buildingIcon: `${base_imgs_url}/Assets/icons/buildingIcon.svg`,
    markerIcon: `${base_imgs_url}/Assets/icons/markerIcon.svg`,
    draftJobIcon: `${base_imgs_url}/Assets/icons/draftJobIcon.svg`,
    moreIcon: `${base_imgs_url}/Assets/icons/moreIcon.svg`,
    jobspaceDocsIcon: `${base_imgs_url}/Assets/icons/jobspaceDocsIcon.svg`,
    folderIcon: `${base_imgs_url}/Assets/icons/folderIcon.svg`,
    editIcon: `${base_imgs_url}/Assets/icons/editIcon.svg`,
    saveBtnIcon: `${base_imgs_url}/Assets/icons/saveBtnIcon.svg`,
    openBoxIcon: `${base_imgs_url}/Assets/icons/open_box.svg`,
    backIcon: `${base_imgs_url}/Assets/icons/backIcon.svg`,
    addIcon: `${base_imgs_url}/Assets/icons/addIcon.svg`,
    sector: `${base_imgs_url}/Assets/icons/sector.svg`,
    keyword: `${base_imgs_url}/Assets/icons/keyword.svg`,
    searchIcon: `${base_imgs_url}/Assets/icons/search-icon.svg`,
    recommendedStamp: `${base_imgs_url}/Assets/icons/recommendedStamp.svg`,
    videoPendingCompletion: `${base_imgs_url}/Assets/icons/videoPendingCompletion.svg`,
    suitcaseIcon: `${base_imgs_url}/Assets/icons/suitcase.svg`,
    appliedIcon: `${base_imgs_url}/Assets/icons/appliedIcon.svg`,
    shortlistedIcon: `${base_imgs_url}/Assets/icons/shortlistedIcon.svg`,
    searchIcon2: `${base_imgs_url}/Assets/icons/searchIcon.svg`,
    lock: `${base_imgs_url}/Assets/icons/lock.svg`,
    ai_analysis_icon: `${base_imgs_url}/Assets/icons/ai_analysis_icon.svg`,
  },
  messaging: {
    sendIcon: `${base_imgs_url}/Assets/icons/sendIcon.svg`,
    minimizeIcon: `${base_imgs_url}/Assets/icons/minimizeIcon.svg`,
    searchIcon: `${base_imgs_url}/Assets/icons/navAvuaPool.svg`,
  },
  employerSettings: {
    delete_employer: `${base_imgs_url}/Assets/icons/delete_employer.svg`,
    deactivate_warning_icon: `${base_imgs_url}/Assets/icons/deactivate_warning_icon.svg`,
    searchIcon: `${base_imgs_url}/Assets/icons/searchIcon.svg`,
  },

  form: {
    user_auth: `${base_imgs_url}/Assets/icons/user_auth.svg`,
    briefcase_auth: `${base_imgs_url}/Assets/icons/briefcase_auth.svg`,
    lock_auth: `${base_imgs_url}/Assets/icons/lock_auth.svg`,
    mail_auth: `${base_imgs_url}/Assets/icons/mail_auth.svg`,
    phone_auth: `${base_imgs_url}/Assets/icons/phone_auth.svg`,
    eye_auth: `${base_imgs_url}/Assets/icons/eye_auth.svg`,
  },

  homePage: {
    leftIcon: `${base_imgs_url}/Assets/icons/leftIcon.svg`,
    rightIcon: `${base_imgs_url}/Assets/icons/rightIcon.svg`,
    videoPreview: `${base_imgs_url}/Assets/imgs/Home_videoPoster.svg`,
    playIcon: `${base_imgs_url}/Assets/icons/playIcon.svg`,
  },

  blogs: {
    noVideoUploaded: `${base_imgs_url}/Assets/icons/noVideoUploaded.svg`,
  },

  MaskingCv: {
    cvIcon: `${base_imgs_url}/Assets/icons/cvIcon.svg`,
    downloadMaskedCv: `${base_imgs_url}/Assets/icons/downloadMaskedCv.svg`,
    attachmentIcon: `${base_imgs_url}/Assets/icons/attachmentIcon.svg`,
    deleteIcon: `${base_imgs_url}/Assets/icons/deleteIcon.svg`,
    downloadMore: `${base_imgs_url}/Assets/icons/downloadMore.svg`,
    infoIcon: `${base_imgs_url}/Assets/icons/infoIcon.svg`,
    blackLineIcon: `${base_imgs_url}/Assets/icons/blackLineIcon.svg`,
  },

  pricing: {
    avua_prising_logo: `${base_imgs_url}/Assets/icons/avua_prising_logo.svg`,
    drop_down: `${base_imgs_url}/Assets/icons/drop_down.svg`,
    card_dropdown: `${base_imgs_url}/Assets/icons/pricing_dropdown_icon.svg`,
  },

  jobPost: {
    postedJob: `${base_imgs_url}/Assets/icons/jobPosted_icon.svg`,
    draft_job: `${base_imgs_url}/Assets/icons/Draft_job.svg`,
  },

  Cv_analysis: {
    Structure_review: `${base_imgs_url}/Assets/icons/Structure_review.svg`,
    skill_section: `${base_imgs_url}/Assets/icons/skill_section.svg`,
    skill_assesment: `${base_imgs_url}/Assets/icons/skill_assesment.svg`,
    performance_review: `${base_imgs_url}/Assets/icons/performance_review.svg`,
    additional_feature: `${base_imgs_url}/Assets/icons/career_oriented.svg`,
    report_additional_feature: `${base_imgs_url}/Assets/icons/career_oriented_report.svg`,
    report_performance: `${base_imgs_url}/Assets/icons/report_performance.svg`,
    report_skill_assesment: `${base_imgs_url}/Assets/icons/report_skill_assesment.svg`,
    report_skill_section: `${base_imgs_url}/Assets/icons/report_skill_section.svg`,
    report_structure: `${base_imgs_url}/Assets/icons/report_structure.svg`,
    paperclip: `${base_imgs_url}/Assets/icons/paperclip.svg`,
    generate_ai: `${base_imgs_url}/Assets/icons/generate_ai.svg`,
    cv_analysis_report: `${base_imgs_url}/Assets/icons/cv_analysis_report.svg`,
    x_cv_analysis: `${base_imgs_url}/Assets/icons/x_cv_analysis.svg`,
    contribution: `${base_imgs_url}/Assets/icons/contribution.svg`,
    selected_contribution: `${base_imgs_url}/Assets/icons/contibution_selected.svg`,
    pleaseWait: `${base_imgs_url}/Assets/icons/pleaseWait.svg`,
    cv_editor: `${base_imgs_url}/Assets/icons/cv_editor.svg`,
    hideReport: `${base_imgs_url}/Assets/icons/hideReport.svg`,
    hideEditor: `${base_imgs_url}/Assets/icons/hideEditor.svg`,
    profile_edit: `${base_imgs_url}/Assets/icons/profile_edit.svg`,
    selected_profile: `${base_imgs_url}/Assets/icons/selected_profile.svg`,
    defaultProfileIcon: `${base_imgs_url}/Assets/icons/defaultProfileIcon.svg`,
    uploadProfileIcone: `${base_imgs_url}/Assets/icons/uploadProfileIcone.svg`,
    preference: `${base_imgs_url}/Assets/icons/preference.svg`,
    education: `${base_imgs_url}/Assets/icons/education.svg`,
    additional_feild: `${base_imgs_url}/Assets/icons/additional_feild.svg`,
    Skills: `${base_imgs_url}/Assets/icons/Skills.svg`,
    awards: `${base_imgs_url}/Assets/icons/awards.svg`,
    projects_edit: `${base_imgs_url}/Assets/icons/projects_edit.svg`,
    certificates: `${base_imgs_url}/Assets/icons/certificates.svg`,
    courses: `${base_imgs_url}/Assets/icons/courses.svg`,
    cv_alert_triangle: `${base_imgs_url}/Assets/icons/cv_alert-triangle.svg`,
    glass_background: `${base_imgs_url}/Assets/icons/Rectangle_glass_cvAnalysis.svg `,
    glass_img: `${base_imgs_url}/Assets/icons/glass_img.png`,
    aiPower: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/aiPower.svg`,
    softSkills: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/softSkills.svg`,
    feedback: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/feedback.svg`,
    ats: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/ats.svg`,
    cvAnalysis: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/cvAnalysis.svg`,
    cvAnalysisNew: `${base_imgs_url}/Assets/imgs/cvAnalysisNew.png`,
    warningReport: `${base_imgs_url}/Assets/icons/warningReport.svg`,
    coverLetter: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/coverLetter.svg`,
    cv_styles: `${base_imgs_url}/Assets/icons/cv_analysis_style.svg`,
    cv_styles_purple: `${base_imgs_url}/Assets/icons/cv_analysis_style_purple.svg`,
    expandResume: `${base_imgs_url}/Assets/icons/expandResume.svg`,
    resume1: `${base_imgs_url}/Assets/icons/resume1.svg`,
    elegant: `${base_imgs_url}/Assets/icons/elegantResume.svg`,
    temp1: `${base_imgs_url}/Assets/imgs/cv_temp_one.svg`,
    temp2: `${base_imgs_url}/Assets/imgs/cv_temp_two.svg`,
    temp3: `${base_imgs_url}/Assets/imgs/cv_temp_three.svg`,
    elegantNew: `${base_imgs_url}/Assets/icons/elegantResumeNew.svg`,
    patent: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/Patent.svg`,
    hobbies: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/hobbies.svg`,
    publication: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/publication.svg`,
    contact: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/contact.svg`,
    checkBox: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/CheckBox.svg`,
    arrorw_rewritten: `${base_imgs_url}/Assets/icons/cvAnalysisIcons/arrow_icon_rewrite.svg`,
  },

  aiSmartHire: {
    laodingQuestion: `${base_imgs_url}/Assets/icons/loadingQuestions.svg`,
  },
};

export const images = {
  careerGrowth: {
    career: `${base_imgs_url}/Assets/imgs/career.svg`,
    careerConfidence: `${base_imgs_url}/Assets/imgs/careerConfidence.svg`,
    careerConfidenceNew: `${base_imgs_url}/Assets/imgs/careerConfidenceNew.png`,
    coverLater: `${base_imgs_url}/Assets/imgs/coverLater.svg`,
    salaryInsight: `${base_imgs_url}/Assets/imgs/salaryInsight.svg`,
    careerPathTracker: `${base_imgs_url}/Assets/imgs/careerPathTracker.svg`,
    atsResumeBuilder: `${base_imgs_url}/Assets/imgs/atsResumeBuilder.svg`,
    aiMockInterview: `${base_imgs_url}/Assets/imgs/aiMockInterview.svg`,
    cvAnalysis: `${base_imgs_url}/Assets/imgs/cvAnalysis.svg`,
    bgImgHeroSection: `${base_imgs_url}/Assets/imgs/bgImgHeroSection.svg`,
    loginImageCareerGrowth: `${base_imgs_url}/Assets/imgs/loginImageCareerGrowth.svg`,
    loginImageCareerGrowth2: `${base_imgs_url}/Assets/imgs/loginImageCareerGrowth2.svg`,
    loginImageCareerGrowthNew: `${base_imgs_url}/Assets/imgs/loginImageCareerGrowthNew.png`,
    testimonial1: `${base_imgs_url}/Assets/imgs/testimonial1.svg`,
    testimonial2: `${base_imgs_url}/Assets/imgs/testimonial2.svg`,
    testimonial3: `${base_imgs_url}/Assets/imgs/testimonial3.svg`,
    testimonial4: `${base_imgs_url}/Assets/imgs/testimonial4.svg`,
  },
  blogs: {
    blog1: `${base_imgs_url}/Assets/imgs/blog1.svg`,
    blog2: `${base_imgs_url}/Assets/imgs/blog2.svg`,
    blog3: `${base_imgs_url}/Assets/imgs/blog3.svg`,
    blogDemiImg: `${base_imgs_url}/Assets/imgs/blogDemiImg.png`,
    heroSection_blog: `${base_imgs_url}/Assets/imgs/heroSection_blog.png`,
  },
  components: {
    sectionSeperator: `${base_imgs_url}/Assets/imgs/z_illustration.svg`,
    companyimgg: `${base_imgs_url}/Assets/imgs/companyimgg.png`,
    resume_glass: `${base_imgs_url}/Assets/imgs/resume_glass.png`,
  },
  unSubscribePage: {
    UnSubscribe: `${base_imgs_url}/Assets/imgs/UnSubscribe.svg`,
  },
  sigup: {
    logo: `${base_imgs_url}/Assets/imgs/Auth_logo.svg`,
    Auth_slider1: `${base_imgs_url}/Assets/imgs/Auth_slider1.svg`,
    Auth_slider2: `${base_imgs_url}/Assets/imgs/Auth_slider2.svg`,
    Auth_slider3: `${base_imgs_url}/Assets/imgs/Auth_slider3.svg`,
    Auth_backgroundImageUrl: `${base_imgs_url}/Assets/imgs/Auth_background.svg`,
    bgLoginImg: `${base_imgs_url}/Assets/imgs/bgLoginImg.png`,
    InfoSliderAuth1: `${base_imgs_url}/Assets/imgs/InfoSliderAuth1.svg`,
    InfoSliderAuth2: `${base_imgs_url}/Assets/imgs/InfoSliderAuth2.svg`,
    InfoSliderAuth3: `${base_imgs_url}/Assets/imgs/InfoSliderAuth3.svg`,
    Auth_page: `${base_imgs_url}/Assets/imgs/Auth_page.svg`,
  },
  footer: {
    New_QR_code: `${base_imgs_url}/Assets/imgs/New_QR_code.svg`,
  },

  aboutUs: {
    avua_logo: `${base_imgs_url}/Assets/imgs/avua_banner.png`,
    Stratigic_Marketing: `${base_imgs_url}/Assets/imgs/aboutus_ils_1.svg`,
    Video_Resume: `${base_imgs_url}/Assets/imgs/aboutus_ils_2.svg`,
    teamMember1: `${base_imgs_url}/Assets/imgs/teamMember1.png`,
    heroImg: `${base_imgs_url}/Assets/imgs/aboutus_hero.webp`,
    videoOutline: `${base_imgs_url}/Assets/imgs/video_outline.svg`,
    AaronImg: `${base_imgs_url}/Assets/imgs/Aaron_Dhaliwal.png`,
    AadarshImg: `${base_imgs_url}/Assets/imgs/Aadarsh_Sharma.png`,
    GauravImg: `${base_imgs_url}/Assets/imgs/Gaurav_Kalia.png`,
    Vission_Mission: `${base_imgs_url}/Assets/imgs/Vission_Mission.svg`,
    Innovation: `${base_imgs_url}/Assets/imgs/Innovation.svg`,
    Efficiency: `${base_imgs_url}/Assets/imgs/Efficiency.svg`,
    Quality: `${base_imgs_url}/Assets/imgs/Quality.svg`,
    Transparency: `${base_imgs_url}/Assets/imgs/Transparency.svg`,
    Simplicity: `${base_imgs_url}/Assets/imgs/Simplicity.svg`,
    Aaron_img: `${base_imgs_url}/Assets/imgs/aaron_img.png`,
    ceo_image: `${base_imgs_url}/Assets/imgs/ceo_image.png`,
  },

  Applicant: {
    Landinglogo: `${base_imgs_url}/Assets/imgs/landing_logo_1.svg`,
    WebLOGO: `${base_imgs_url}/Assets/imgs/WebLOGO.png`,
    grid_lines: `${base_imgs_url}/Assets/imgs/grid_lines.svg`,
    blank_profiles: `${base_imgs_url}/Assets/imgs/blank_profiles.svg`,
    suitcase: `${base_imgs_url}/Assets/imgs/suitcase.svg`,
    time_value: `${base_imgs_url}/Assets/imgs/time_value.svg`,
    ils1: `${base_imgs_url}/Assets/imgs/landingPage_1_ils_1.svg`,
    ils2: `${base_imgs_url}/Assets/imgs/landingPage_1_ils_2.svg`,
    ils3: `${base_imgs_url}/Assets/imgs/landingPage_1_ils_3.svg`,
    ils4: `${base_imgs_url}/Assets/imgs/landingPage_1_ils_4.svg`,
    ils5: `${base_imgs_url}/Assets/imgs/landingPage_1_ils_5.svg`,
    arrow1: `${base_imgs_url}/Assets/imgs/landingPage_1_arrow_1.svg`,
    arrow2: `${base_imgs_url}/Assets/imgs/landingPage_1_arrow_2.svg`,
    arrow3: `${base_imgs_url}/Assets/imgs/landingPage_1_arrow_3.svg`,
    arrow4: `${base_imgs_url}/Assets/imgs/landingPage_1_arrow_4.svg`,
    arrow5: `${base_imgs_url}/Assets/imgs/landingPage_1_arrow_5.svg`,
    lines: `${base_imgs_url}/Assets/imgs/landingPage_lines.svg`,
    heroImg: `${base_imgs_url}/Assets/imgs/landingPage_1_hero.svg`,
    subHeroImg1: `${base_imgs_url}/Assets/imgs/landingPage_subHero_1.svg`,
    subHeroImg2: `${base_imgs_url}/Assets/imgs/landingPage_subHero_2.svg`,
    subHeroImg3: `${base_imgs_url}/Assets/imgs/landingPage_subHero_3.svg`,
    subHeroImg4: `${base_imgs_url}/Assets/imgs/landingPage_subHero_4.svg`,
    subHeroImg5: `${base_imgs_url}/Assets/imgs/landingPage_subHero_5.svg`,
    subHeroImg6: `${base_imgs_url}/Assets/imgs/landingPage_subHero_6.svg`,
    subHeroImg7: `${base_imgs_url}/Assets/imgs/avua_match_score.svg`,
    subHeroImg8: `${base_imgs_url}/Assets/imgs/landing_recommendedJobs.svg`,
    subHeroImg9: `${base_imgs_url}/Assets/imgs/landing_appliedJobs.svg`,
    screening: `${base_imgs_url}/Assets/imgs/screening.svg`,
    Create_job: `${base_imgs_url}/Assets/imgs/Create_job.svg`,
    self_shine: `${base_imgs_url}/Assets/imgs/self-shine.svg`,
    newOpportunity: `${base_imgs_url}/Assets/imgs/newOpportunity.svg`,
    applicantPoster: `${base_imgs_url}/Assets/imgs/applicantPoster.svg`,
    guide_back: `${base_imgs_url}/Assets/imgs/guide_back.svg`,
  },

  Employer: {
    aiPowered: `${base_imgs_url}/Assets/imgs/employer_ils_1.svg`,
    videoResume: `${base_imgs_url}/Assets/imgs/employer_ils_2.svg`,
    verifiedTalent: `${base_imgs_url}/Assets/imgs/employer_ils_3.svg`,
    discoverTop: `${base_imgs_url}/Assets/imgs/employer_ils_5.svg`,
    engage: `${base_imgs_url}/Assets/imgs/employer_ils_6.svg`,
    Team_Members: `${base_imgs_url}/Assets/imgs/employer_ils_7.svg`,
    postOpportunity: `${base_imgs_url}/Assets/imgs/employer_ils_4.svg`,
    heroImg: `${base_imgs_url}/Assets/imgs/microBanner3.webp`,
    heroImg1: `${base_imgs_url}/Assets/imgs/landing_employer.svg`,
    poolDefaultImg: `${base_imgs_url}/Assets/icons/default-pool-img.svg`,
    poolNarrowDownSearch: `${base_imgs_url}/Assets/imgs/poolNarrowDownSearch.png`,
    poolModifySearch: `${base_imgs_url}/Assets/icons/poolModifySearch.svg`,
    executiveImg: `${base_imgs_url}/Assets/imgs/executiveImg.png`,
    reviewPending: `${base_imgs_url}/Assets/imgs/reviewPending.png`,
    reviewPendingImg: `${base_imgs_url}/Assets/imgs/reviewPendingImg.svg`,
    reviewUnsuccessfull: `${base_imgs_url}/Assets/imgs/reviewUnsuccessfull.png`,
  },
  Recruiter: {
    ils: `${base_imgs_url}/Assets/imgs/recruit_ils_1.svg`,
    heroImg: `${base_imgs_url}/Assets/imgs/microBanner4.webp`,
  },
  verify: {
    backgroundResult: `${base_imgs_url}/Assets/imgs/Verify_Result_background.svg`,
    logo: `${base_imgs_url}/Assets/imgs/Auth_logo.svg`,
    ProcessPage: `${base_imgs_url}/Assets/imgs/Verify_ProcessImg1.svg`,
    sideRight2: `${base_imgs_url}/Assets/imgs/Verify_ProcessImg2.svg`,
    sideRight3: `${base_imgs_url}/Assets/imgs/Verify_ProcessImg3.svg`,
    passIcon: `${base_imgs_url}/Assets/imgs/pass_img.svg`,
  },

  landingPage: {
    noise_bg: `${base_imgs_url}/Assets/imgs/noise_bg.svg`,
    landing_line: `${base_imgs_url}/Assets/imgs/landing_page_line.webp`,
    landing_line_new: `${base_imgs_url}/Assets/imgs/landing_page_line_new.webp`,
    landing_wave: `${base_imgs_url}/Assets/imgs/landing_page_wave.svg`,
    tech_lines: `${base_imgs_url}/Assets/imgs/technology_lines.svg`,
    technologyIls: `${base_imgs_url}/Assets/imgs/technology_ils.svg`,
    technologyIlsLight: `${base_imgs_url}/Assets/imgs/technology_ils_light.svg`,
    energyIls: `${base_imgs_url}/Assets/imgs/energy_ils.svg`,
    energyIlsLight: `${base_imgs_url}/Assets/imgs/energy_ils_light.svg`,
    financeIls: `${base_imgs_url}/Assets/imgs/finance_ils.svg`,
    financeIlsLight: `${base_imgs_url}/Assets/imgs/finance_ils_light.svg`,
    pharmaIls: `${base_imgs_url}/Assets/imgs/pharma_ils.svg`,
    pharmaIlsLight: `${base_imgs_url}/Assets/imgs/pharma_ils_light.svg`,
    deep_ai_matching: `${base_imgs_url}/Assets/imgs/deep_ai_matching.webp`,
    ai_video_processing: `${base_imgs_url}/Assets/imgs/ai_video_processing.webp`,
    intelligent_parsing: `${base_imgs_url}/Assets/imgs/intelligent_parsing.webp`,
    jd_parser: `${base_imgs_url}/Assets/imgs/jd_parser.webp`,
    gpt_verification: `${base_imgs_url}/Assets/imgs/gpt_verification.webp`,
    innovation_bulb: `${base_imgs_url}/Assets/imgs/innovation_bulb.svg`,
    circleBg: `${base_imgs_url}/Assets/imgs/landingPage_circles.svg`,
    aiMatching: `${base_imgs_url}/Assets/animations/AiMacthing.gif`,
    CVParse: `${base_imgs_url}/Assets/animations/resumeVid.gif`,
    Parsers: `${base_imgs_url}/Assets/animations/parse.gif`,
    GPT: `${base_imgs_url}/Assets/animations/Chatgpt.gif`,
    Animated: `${base_imgs_url}/Assets/animations/emloyerPageAnimation.json`,
    AnimatedMobile: `${base_imgs_url}/Assets/animations/AnimatedMobile.json`,
    Video_Resume: `${base_imgs_url}/Assets/animations/Video_Resume.gif`,
    ApplicantLanding: `${base_imgs_url}/Assets/animations/ApplicantLanding.gif`,
    pharma: `${base_imgs_url}/Assets/animations/pharma.json`,
    finance: `${base_imgs_url}/Assets/animations/finance_gif.json`,
    tech: `${base_imgs_url}/Assets/animations/technology.json`,
    energy: `${base_imgs_url}/Assets/animations/energy.json`,
    avuaPoolBanner: `${base_imgs_url}/Assets/imgs/avuaPoolBanner.svg`,
    avuaPoolBannerMobile: `${base_imgs_url}/Assets/imgs/avuaPoolBannerMobile.svg`,
    applicantFlow: `${base_imgs_url}/Assets/animations/applicantFlow.json`,
    applicantFlowMobile: `${base_imgs_url}/Assets/animations/applicantFlowMobile.json`,
    JobSearch: `${base_imgs_url}/Assets/animations/JobSearch.gif`,
    engage: `${base_imgs_url}/Assets/imgs/engage.svg`,
    teamMember: `${base_imgs_url}/Assets/imgs/teamMember.svg`,
    postOpportunity: `${base_imgs_url}/Assets/animations/postOpportunity.gif`,
    Cv_flow: `${base_imgs_url}/Assets/animations/Cv_flow.gif`,
    job_search: `${base_imgs_url}/Assets/animations/job_search.gif`,
    Video_Resume1: `${base_imgs_url}/Assets/imgs/Video_Resume.svg`,
    backgroundImageUrl: `${base_imgs_url}/Assets/icons/sectionBgImg.svg`,
    videoPreview: `${base_imgs_url}/Assetsssets/imgs/videoPreview.png`,
  },

  UploadResume: {
    illustration: `${base_imgs_url}/Assets/imgs/uploadResume_ils.svg`,
    avua_logo: `${base_imgs_url}/Assets/imgs/avua_banner.png`,
  },
  dashboard: {
    My_Job: `${base_imgs_url}/Assets/imgs/My_Job.svg`,
    Message: `${base_imgs_url}/Assets/imgs/Message.svg`,
    ShorListedjob: `${base_imgs_url}/Assets/imgs/ShorListedJob.svg`,
  },
  jobspace: {
    defaultUser: `${base_imgs_url}/Assets/imgs/defaultUser.png`,
    no_applied_candidate: `${base_imgs_url}/Assets/imgs/no_applied_job.svg`,
  },
  employer_dashboard: {
    post_job: `${base_imgs_url}/Assets/imgs/post_job_ils.svg`,
  },
  page_not_found: {
    page_404: `${base_imgs_url}/Assets/icons/404_pageNotFound.svg`,
    go_home: `${base_imgs_url}/Assets/icons/go_to_home.svg `,
  },

  candidates: {
    ApplcantNotFound: `${base_imgs_url}/Assets/imgs/applicant_not_found.svg`,
    avuaGuaranTee: `${base_imgs_url}/Assets/imgs/guarantee_avua.svg`,
  },

  avuaPool: {
    default_pool_screen: `${base_imgs_url}/Assets/imgs/default_pool_screen.svg`,
  },
};

export const video = {
  landingPage: {
    video: `${base_imgs_url}/Assets/video/Timeline 1.mov`,
    avua_into_video: `${base_imgs_url}/Assets/video/avua-intro-video.mp4`,
  },
  aboutUsPage: {
    video: `${base_imgs_url}/Final_Version_v3.mp4`,
  },
};

export const models = {
  hand3Dmodel: `${base_imgs_url}/Assets/models/energy.gltf`,
};

export const animations = {
  Auth: {
    email: `${base_imgs_url}/Assets/animations/email.json`,
    passwordChaged: `${base_imgs_url}/Assets/animations/passwordChanged.json`,
  },
  avuaPool: {
    jobSearch: `${base_imgs_url}/Assets/animations/avua_pool_JobSearch.json`,
  },
  postJob: {
    jobPostBasket: `${base_imgs_url}/Assets/animations/jobPostBasket.json`,
  },
  dashboard: {
    eligiblity: `${base_imgs_url}/Assets/animations/eligibiltyCheck.json`,
    uploadResume: `${base_imgs_url}/Assets/animations/uploading_Resume.json`,
  },
  masking: {
    maskingCvGif: `${base_imgs_url}/Assets/animations/maskingCvGif.gif`,
    analyzingCv: `${base_imgs_url}/Assets/animations/analyzingCv.gif`,
    finalizingCv: `${base_imgs_url}/Assets/animations/finalizingCv.gif`,
  },
  landingPage: {
    Finance: `${base_imgs_url}/Assets/animations/Finance.json`,
    Pharmaceuticals: `${base_imgs_url}/Assets/animations/Pharmaceuticals.json`,
  },
  vetting: {
    fail: `${base_imgs_url}/Assets/animations/fail.json`,
    Process1Animation: `${base_imgs_url}/Assets/animations/ProcessPage_1_Animation1.json`,
    UserInterfaceAnimation: `${base_imgs_url}/Assets/animations/ProcessPage_2_Animation2.json`,
    VideoResumeAnimation: `${base_imgs_url}/Assets/animations/ProcessPage_3_Animation3.json`,
    ConfirmVerified: `${base_imgs_url}/Assets/animations/ConfirmVerfied.json`,
    vetting_pass: `${base_imgs_url}/Assets/animations/Vetting_congratulations.json`,
    vetting_fail: `${base_imgs_url}/Assets/animations/Vetting_fail.json`,
    passAnimation: `${base_imgs_url}/Assets/animations/pass_vetting.json`,
  },
  Employer: {
    Searching: `${base_imgs_url}/Assets/animations/Searching.json`,
  },
  utils: {
    jdParser: `${base_imgs_url}/Assets/animations/jdParser.json`,
    loadingQuestion: `${base_imgs_url}/Assets/animations/Artboard.json`,
  },
  Cv_analysis: {
    loadingScreen: `${base_imgs_url}/Assets/animations/cv_analysis_loading.json`,
    cvUploading_analysis: `${base_imgs_url}/Assets/animations/cvUploading_analysis.json`,
    laodingResume: `${base_imgs_url}/Assets/animations/loadingResume.json`,
  },
};
export const publicImages = {
  images: {
    cameraimage: `${base_imgs_url}/Assets/public/cameraimage.svg`,
    vector1: `${base_imgs_url}/Assets/public/vector1.svg`,
    arrowright: `${base_imgs_url}/Assets/public/arrowright.svg`,
    confirmdelete: `${base_imgs_url}/Assets/public/confirmdelete.svg`,
    backarrow: `${base_imgs_url}/Assets/public/backarrow.svg`,
    group: `${base_imgs_url}/Assets/public/group-2014.svg`,
    clockicon: `${base_imgs_url}/Assets/public/clockicon.svg`,
    resumebtnline: `${base_imgs_url}/Assets/public/resumebtnline.svg`,
    frequencyanalyzer: `${base_imgs_url}/Assets/public/frequencyanalyzer.svg`,
    rectangle2x: `${base_imgs_url}/Assets/public/rectangle-407@2x.png`,
  },
};
export const mockInterviews = {
  icons: {
    mockInterviewIcon: `${base_imgs_url}/Assets/icons/ai-mock-interviews/mock_interview-icon.svg`,
  },
  svg: {
    createInterviewDefault: `${base_imgs_url}/Assets/icons/ai-mock-interviews/createInterviewDefault.svg`,
    defaultScreen: `${base_imgs_url}/Assets/icons/ai-mock-interviews/defaultScreen.svg`,
    mockInterviewLoader: `${base_imgs_url}/Assets/animations/loadingResume.json`,
    idealAnswer: `${base_imgs_url}/Assets/icons/ai-mock-interviews/idealAnswer.svg`,
  },
};

export const PdfDoc = {
  file: {
    Interview_Guide: `${base_imgs_url}/Pdf/Interview_Guide.pdf`,
    Interview_Prepration_Guide: `${base_imgs_url}/Pdf/avua_AI_Interview _GUIDE_.pdf`,
  },
};
