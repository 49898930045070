// resumeDataSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //----------get-started page---------
  id: "",
  jd_url: "",
  industry: "",
  is_basic_job: false,
  job_title: {
    id: "",
    title: "",
    user_generated: false,
  },

  //----------job title page---------
  // job title in this page is same as job_title above
  roles: "",
  skills: [],
  keywords: [],

  //----------job details page----------
  company: {
    id: "",
    name: "",
  },
  employment_mode: [],
  country:{
    id:"",
    code:"",
    name:""
  }, 
  job_location: {
    id: "",
    name: "",
    state: "",
    country: "",
    code:""
  },
  job_type: [],
  vacancies: 0,
  job_space: {
    id: "",
    title: "",
  },
  from_location_only: false,
  include_relocation: false,
  is_international: false,

  //----------preferred candidate qualification page---------
  qualification_level: null,
  qualification: {
    id: "",
    name: "",
  },
  field_of_study: {
    id: "",
    name: "",
  },

  field_of_study_pref: false,
  salary_type: "",

  currency: {
    id: "147d3656-bdb9-4d9a-9ec5-e5888be55e64",
    country_id: "d797732d-1ca2-4e60-8ea3-a4f835bd10c1",
    code: "USD"
  },

  min_expected_salary: {
    id: "",
    salary: "",
  },

  max_expected_salary: {
    id: "",
    salary: "",
  },

  expected_salary: {
    visibility: null,
  },
  
  required_exp: {
    min: 0,
    max: 0,
  },
  availability: 0,
 


  active: true,
  is_private: null,
  is_draft: false,
  
  prompt: "",
};

const aiPostJobDataSlice = createSlice({
  name: "aiPostJobData",
  initialState,
  reducers: {
    setPostJobData: (state, action) => {
      return action.payload;
    },
    setActivePrivateDraft: (state, action) => {
      state.active = action.payload.active;
      state.is_private = action.payload.is_private;
      state.is_draft = action.payload.is_draft;
    },
    clearData: (state) => {
      // Reset state to initial values
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});

export const {
  setPostJobData,
  clearData,
} = aiPostJobDataSlice.actions;
export const selectAiPostJobData = (state) => state.aiPostJobData;
export default aiPostJobDataSlice.reducer;
