import { useNavigate } from "react-router-dom";

const DropDownMenu = () => {
  const routesExists= ["applicant", "growth-plan","cv-analysis"];
  const url = window.location.href.split("/").pop();
  const navigate = useNavigate();

  const togglePath = (path) => {
    navigate(path);
  };

  return (
    <div className="bg-[#FFFFFF] w-fit flex justify-center items-center absolute top-14 p-2 rounded-[18px] border-[1px] border-[#D9D9D9]">
      {
        routesExists.includes(url) ? (
          <div className="w-full min-w-[260px] grid grid-cols-1 gap-4">
          <div
            onClick={() => togglePath("/applicant/cv-analysis")}
            className="hover:bg-fr p-4 rounded-md cursor-pointer"
          >
            <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
            CV Analysis
            </p>
            <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
            Get detailed insights on your CV
            </p>
          </div>
          <div className="hover:bg-fr p-4 rounded-md">
               <div className="flex items-center space-x-2 whitespace-nowrap">
                 <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
                 Mock AI Interview
                 </p>
                 <div className="w-fit h-fit bg-[#E7D4FF]">
                   <p className="text-caption text-[#6B3BAB] font-[500] px-1">
                     coming soon
                   </p>
                 </div>
               </div>
               <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
               Prepare for your next interview
               </p>
             </div>
          </div>  ) : (
             <div className="w-full min-w-[600px] grid grid-cols-2 gap-4">
             <div
               onClick={() => togglePath("/employer")}
               className="hover:bg-fr p-4 rounded-md cursor-pointer"
             >
               <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
                 Post a Job Opening
               </p>
               <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
                 Our AI algorithms will connect you with relevant applicants in seconds
               </p>
             </div>
             <div
               onClick={() => togglePath("/avua-pool-page")}
               className="hover:bg-fr p-4 rounded-md cursor-pointer"
             >
               <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
                 avua Pool
               </p>
               <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
                 Unlimited access to our premium talent database
               </p>
             </div>
             <div className="hover:bg-fr p-4 rounded-md">
               <div className="flex items-center space-x-2 whitespace-nowrap">
                 <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
                   AI Interviews
                 </p>
                 <div className="w-fit h-fit bg-[#E7D4FF]">
                   <p className="text-caption text-[#6B3BAB] font-[500] px-1">
                     coming soon
                   </p>
                 </div>
               </div>
               <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
                 Streamline the interview process with our intelligent AI
               </p>
             </div>
             <div className="hover:bg-fr p-4 rounded-md">
               <div className="flex items-center space-x-2 whitespace-nowrap">
                 <p className="text-[18px] font-outfit font-[500] leading-[27px] text-[#424242]">
                  Ideal Match
                 </p>
                 <div className="w-fit h-fit bg-[#E7D4FF]">
                   <p className="text-caption text-[#6B3BAB] font-[500] px-1">
                     coming soon
                   </p>
                 </div>
               </div>
               <p className="text-[14px] font-normal leading-[21px] font-outfit text-[#A2A2A2]">
                 Upload the CV of your ideal candidate and discover matching profiles
               </p>
             </div>
           </div>
          )
      }
     
    </div>
  );
};

export default DropDownMenu;
