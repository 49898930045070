// store.js
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Local storage as the storage engine

// Import your slices here
import authReducer from './slices/authSlice'; 
import authTypeReducer from './slices/authTypeSlice'; 
import currentPageSlice from './slices/currentPageSlice';
import questionsSlice from './slices/questionsSlice';
import resumeDataSlice from './slices/resumeDataSlice';
import userPreferenceSlice from './slices/userSlices';
import SessionSlice from './slices/SessionSlice';
import postJobSlice from './slices/postJobSlice';
import aiPostJobSlice from './slices/aiPostJobSlice';
import employerProfileSlice from "./slices/employerProfileSlice";
import companyDataSlice from './slices/ComanyDataSlice';
import  AddCompanySlice from './slices/employerCompany'
import appliedjobsSlice from './slices/appliedJobs';
import postjobPrefrenceseSlice from "./slices/JobPrefrenceSlice"
import industrySlice from './slices/industrySlice';
import poolCreditsSlice from './slices/poolCreditsSlice';
import RecommendedJobSlice from './slices/RecommendedJobSlice';
import applicationRedirectSlice from './slices/applicationRedirectSlice'

import CvAnalysisDataSlice from "./slices/cvAnalysisSlice";

const rootReducer = combineReducers({
  // Add your slices as properties here
  auth: authReducer,
  authType: authTypeReducer,
  currentPage: currentPageSlice,
  questions: questionsSlice, // Add the questionsSlice
  resumeData: resumeDataSlice,
  userPreferences: userPreferenceSlice,
  session: SessionSlice,
  postJobData: postJobSlice,
  aiPostJobData: aiPostJobSlice,
  employerProfileData: employerProfileSlice,
  companyData: companyDataSlice,
  company: AddCompanySlice,
  appliedJobs: appliedjobsSlice,
  postJobPrefrence: postjobPrefrenceseSlice,
  postjobIndustry: industrySlice,
  poolViewCredits: poolCreditsSlice,
  recommendedJob:  RecommendedJobSlice,
  applicationRedirect : applicationRedirectSlice,
  CvAnalysisData: CvAnalysisDataSlice,
});

// Configure the Redux Persist options
const persistConfig = {
  key: "root", // Key to store the data in local storage
  storage,
  // You can add more configuration options here, like whitelist/blacklist or transform functions
  whitelist: [
    "auth",
    "session",
    "employerProfileData",
    "postJobData",
    "appliedJobs",
    "postjobIndustry",
    "CvAnalysisData",
  ],
  // Blacklist the questions slice
  blacklist: [
    "questions",
    "currentPage",
    "resumeData",
    "userPreferences",
    "authType",
    "companyData",
    "AddCompanySlice",
    "postJobPrefrence",
    "recommendedJob",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Add middleware or other configuration options as needed
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'], // Ignore this specific action
      },
    }),
});


const persistor = persistStore(store);

export { store, persistor };





// OLD CONFIG
// const store = configureStore({
//   reducer: persistedReducer,
//   // Add middleware or other configuration options as needed
//   serializableCheck: false
// });